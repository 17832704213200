import { TooltipProps } from "@blueprintjs/core";
import React from "react";
import styled, { css } from "styled-components";

import { HexTooltip } from "../../../hex-components";
import { MinimalCategory } from "../../../hooks/useProjectLabelsForHex";

import { CategoryIcon } from "./OrganizationIcons";

export interface CategoryLabelProps {
  className?: string;
  category: Omit<MinimalCategory, "id">;
  /** Condensed form with smaller icon & less padding */
  condensed?: boolean;
  /** Icon-only form */
  minimal?: boolean;
  muted?: boolean;
  descriptionTooltipProps?: Omit<TooltipProps, "content">;
}

export const CategoryLabel: React.ComponentType<CategoryLabelProps> =
  React.memo(function CategoryLabel({
    category,
    className,
    condensed,
    descriptionTooltipProps,
    minimal,
    muted,
  }: CategoryLabelProps) {
    const content = (
      <CategoryLabelWrapper
        $condensed={condensed}
        $minimal={minimal}
        $muted={muted}
        className={className}
      >
        <CategoryIcon $color={category.color} />
        {!minimal && <CategoryName>{category.name}</CategoryName>}
      </CategoryLabelWrapper>
    );

    if (
      category.description == null ||
      category.description.trim().length === 0
    ) {
      return content;
    }
    return (
      <HexTooltip
        content={category.description}
        placement="bottom"
        {...descriptionTooltipProps}
      >
        {content}
      </HexTooltip>
    );
  });

const CategoryName = styled.div`
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
`;

export const CategoryLabelWrapper = styled.div<{
  $condensed?: boolean;
  $minimal?: boolean;
  $muted?: boolean;
}>`
  display: flex;
  align-items: center;
  min-width: 0;

  color: ${({ $muted, theme }) =>
    $muted ? theme.fontColor.MUTED : theme.fontColor.DEFAULT};

  ${({ $condensed }) =>
    $condensed
      ? css`
          height: 18px;
          font-size: ${({ theme }) => theme.fontSize.SMALL};
          padding: 2px;
          gap: 3px;

          ${CategoryIcon} {
            height: 12px;
            width: 5px;
          }
        `
      : css`
          height: 22px;
          font-size: ${({ theme }) => theme.fontSize.SMALL};
          line-height: 16px;
          padding: 4px 6px;
          gap: 4px;
        `}

  ${({ $condensed, $minimal }) =>
    $minimal &&
    css`
      justify-content: center;

      ${$condensed
        ? css`
            width: 10px;
          `
        : css`
            width: 22px;
          `}
    `};
`;
