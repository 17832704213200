// eslint-disable-next-line no-restricted-imports -- TODO DES-589 replace blueprint <Text/> component
import {
  CellId,
  FilledDynamicValueTableColumnType,
  SemanticAwareColumn,
  SemanticAwareFieldGroup,
  SemanticDatasetName,
  assertNever,
} from "@hex/common";
import { filter } from "fuzzaldrin-plus";
import { default as React, useCallback, useMemo } from "react";
import styled, { css } from "styled-components";

import { ExistingJoinConfig } from "../../explore-v2/joins/ExploreConfigureJoin.js";
import {
  HexCollapse,
  HexCollapseHeader,
  ToggleHeader,
} from "../../hex-components/HexCollapse.js";
import { HexMenu, HexMenuDivider } from "../../hex-components/HexMenuItem.js";
import { HexNonIdealState } from "../../hex-components/HexNonIdealState.js";
import { HexTooltip } from "../../hex-components/HexTooltip.js";
import { useCellContentsSelector } from "../../hex-version-multiplayer/state-hooks/cellContentsStateHooks.js";
import { useValidateMagicJoin } from "../../magic/useValidateMagicJoin.js";
import { DataSourceTablePill } from "../cell/shared/scope-select/DataSourceTablePill.js";
import { DatasetIcon } from "../icons/CustomIcons.js";

export interface SemanticAwareFieldListProps {
  fieldGroups: SemanticAwareFieldGroup[];
  renderListItem: (
    item: SemanticAwareColumn,
    isMeasure: boolean,
  ) => JSX.Element | null;
  className?: string;
  disabled?: boolean;
  query?: string;
  headerRightElement?: (queryPath: SemanticDatasetName[]) => JSX.Element | null;
  sort?: SemanticAwareFieldListSort;
  cellId: CellId;
}

export enum SemanticAwareFieldListSort {
  "SCHEMA" = "SCHEMA",
  "DATA_TYPE" = "DATA_TYPE",
  "ALPHANUMERIC" = "ALPHANUMERIC",
}

export interface SemanticAwareFieldListItemProps {
  field: SemanticAwareColumn;
  cellId: CellId;
  isMeasure: boolean;
}

export const SemanticAwareFieldList: React.ComponentType<SemanticAwareFieldListProps> =
  React.memo(function SemanticAwareFieldList(
    props: SemanticAwareFieldListProps,
  ) {
    const {
      cellId,
      className,
      disabled = false,
      headerRightElement,
      query,
      renderListItem,
      sort,
      ...rest
    } = props;

    let contents;
    let showHeaders = false;

    const hasSemanticInput = useCellContentsSelector({
      cellId,
      selector: (c) => {
        if (c.__typename !== "ExploreCell") {
          throw new Error(`Expected ExploreCell, got ${c.__typename}`);
        }
        return c.semanticProjectId != null;
      },
    });

    const filterFields = useCallback(
      (items: SemanticAwareColumn[]) => {
        if (query && query.length > 0) {
          return filter(items, query, {
            key: "columnId",
            usePathScoring: false,
          });
        } else {
          return items;
        }
      },
      [query],
    );

    const fieldGroups = useMemo(() => {
      return sort != null
        ? sortFieldGroups(rest.fieldGroups, sort)
        : rest.fieldGroups;
    }, [rest.fieldGroups, sort]);

    const filteredGroupElements = fieldGroups.flatMap((group) => {
      const dimensions = filterFields(group.columns);
      const measures = filterFields(group.measures);

      const nestedGroups = group.children;
      const filteredNestedGroupsElements =
        nestedGroups.flatMap((nestedGroup) => {
          const nestedDimensions = filterFields(nestedGroup.columns);
          const nestedMeasures = filterFields(nestedGroup.measures);

          if (nestedDimensions.length !== 0 || nestedMeasures.length !== 0) {
            return [
              <FieldGroupWithCollapsibleHeader
                key={nestedGroup.name}
                dimensions={nestedDimensions}
                disabled={disabled}
                hasSemanticInput={hasSemanticInput}
                headerRightElement={headerRightElement}
                measures={nestedMeasures}
                nested={true}
                queryPath={nestedGroup.queryPath}
                renderListItem={renderListItem}
                title={nestedGroup.title}
              />,
            ];
          }
          return [];
        }) ?? [];

      // if there is only a single field group and it doesn't have any
      // nested groups, then no need to render collapsible header
      showHeaders = fieldGroups.length > 1 || nestedGroups.length > 0;

      if (
        dimensions.length !== 0 ||
        measures.length !== 0 ||
        filteredNestedGroupsElements.length !== 0
      ) {
        if (showHeaders) {
          return [
            <FieldGroupWithCollapsibleHeader
              key={group.name}
              dimensions={dimensions}
              disabled={disabled}
              hasSemanticInput={hasSemanticInput}
              headerRightElement={headerRightElement}
              measures={measures}
              nestedGroupsElements={filteredNestedGroupsElements}
              queryPath={group.queryPath}
              renderListItem={renderListItem}
              title={group.title}
            />,
          ];
        }
        return [
          <FieldGroup
            key={group.name}
            dimensions={dimensions}
            disabled={disabled}
            hasSemanticInput={hasSemanticInput}
            measures={measures}
            renderListItem={renderListItem}
            title={group.title}
          />,
        ];
      }
      return [];
    });

    contents = filteredGroupElements;

    if (filteredGroupElements.length === 0) {
      contents = (
        <HexNonIdealState
          $autoHeight={true}
          $minimal={true}
          $small={true}
          title="No fields found"
        />
      );
    }

    return (
      <FieldsContainer $hasGroupHeader={showHeaders} className={className}>
        {contents}
      </FieldsContainer>
    );
  });

interface FieldGroupProps {
  title: string;
  dimensions: SemanticAwareColumn[];
  measures: SemanticAwareColumn[];
  renderListItem: (
    item: SemanticAwareColumn,
    isMeasure: boolean,
  ) => JSX.Element | null;
  disabled: boolean;
  nested?: boolean;
  hasSemanticInput: boolean;
}

const FieldGroup: React.ComponentType<FieldGroupProps> = React.memo(
  function FieldGroup({
    dimensions,
    disabled,
    hasSemanticInput,
    measures,
    renderListItem,
  }) {
    return (
      <FieldGroupContainer>
        {measures.length > 0 && (
          <List $disabled={disabled}>
            <HexMenuDivider title="Measures" />
            {measures.map((item) => renderListItem(item, true))}
          </List>
        )}
        <List $disabled={disabled}>
          {measures.length > 0 && (
            <HexMenuDivider
              title={hasSemanticInput ? "Dimensions" : "Columns"}
            />
          )}
          {dimensions.map((item) => renderListItem(item, false))}
        </List>
      </FieldGroupContainer>
    );
  },
);

type FieldGroupWithCollapsibleHeaderProps = FieldGroupProps & {
  nested?: boolean;
  nestedGroupsElements?: React.ReactNode[];
  queryPath: SemanticDatasetName[];
  headerRightElement?: (
    queryPath: SemanticDatasetName[],
    existingConfig?: ExistingJoinConfig,
  ) => JSX.Element | null;
  hasSemanticInput: boolean;
};

const FieldGroupWithCollapsibleHeader: React.ComponentType<FieldGroupWithCollapsibleHeaderProps> =
  React.memo(function FieldGroupWithCollapsibleHeader(props) {
    const {
      hasSemanticInput,
      headerRightElement,
      nested,
      nestedGroupsElements,
      queryPath,
      title,
    } = props;

    const {
      existingJoinConfig,
      hasError,
      hasMagicGeneratedJoin,
      hasTimeout,
      isLoading,
    } = useValidateMagicJoin({
      queryPath,
    });

    return (
      <StyledHexCollapse
        $nested={nested}
        isOpenDefault={!hasMagicGeneratedJoin}
        title={
          <>
            <TitleContainer>
              <HexTooltip
                content="Magic configured an invalid join; please click edit to resolve issues."
                disabled={!hasError && !hasTimeout}
              >
                <StyledDataSourceTablePill
                  iconOverride={hasSemanticInput ? <DatasetIcon /> : undefined}
                  loading={isLoading}
                  name={title}
                  pillTheme={hasError ? "RED" : undefined}
                  warningIntent={
                    hasTimeout ? "warning" : hasError ? "danger" : undefined
                  }
                />
              </HexTooltip>
              {headerRightElement?.(queryPath, existingJoinConfig)}
            </TitleContainer>
          </>
        }
      >
        <FieldGroup {...props} />
        {nestedGroupsElements && <div>{nestedGroupsElements}</div>}
      </StyledHexCollapse>
    );
  });

function sortFieldGroups(
  fieldGroups: SemanticAwareFieldGroup[],
  sort: SemanticAwareFieldListSort,
): SemanticAwareFieldGroup[] {
  return fieldGroups.map((fieldGroup) => {
    return {
      ...fieldGroup,
      columns: sortFields(fieldGroup.columns, sort),
      measures: sortFields(fieldGroup.measures, sort),
    };
  });
}

function sortFields(
  fields: SemanticAwareColumn[],
  sort: SemanticAwareFieldListSort,
): SemanticAwareColumn[] {
  switch (sort) {
    case SemanticAwareFieldListSort.SCHEMA:
      return fields;
    case SemanticAwareFieldListSort.DATA_TYPE:
      return [...fields].sort((a, b) => {
        const aSort = columnTypeToSortValue(a.columnType);
        const bSort = columnTypeToSortValue(b.columnType);

        if (aSort === bSort) {
          return a.columnId.localeCompare(b.columnId);
        }

        return aSort - bSort;
      });
    case SemanticAwareFieldListSort.ALPHANUMERIC:
      return [...fields].sort((a, b) => a.columnId.localeCompare(b.columnId));
    default:
      return assertNever(sort, sort);
  }
}

function columnTypeToSortValue(
  columnType: FilledDynamicValueTableColumnType,
): number {
  switch (columnType) {
    case "DATETIMETZ":
    case "DATETIME":
    case "DATE":
    case "TIME":
      return 0;
    case "NUMBER":
      return 1;
    case "STRING":
    case "UNKNOWN":
      return 2;
    case "BOOLEAN":
      return 3;
    default:
      assertNever(columnType, columnType);
  }
}

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* height of xs button */
  min-height: 21px;
  gap: 12px;
`;

const StyledDataSourceTablePill = styled(DataSourceTablePill)`
  font-weight: ${({ theme }) => theme.fontWeight.NORMAL};
  overflow: hidden;
`;

const FieldsContainer = styled.div<{ $hasGroupHeader: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin: 0 -16px;
  padding: 0 ${({ $hasGroupHeader }) => ($hasGroupHeader ? `20px` : `16px`)};
`;

const FieldGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
`;

const StyledHexCollapse = styled(HexCollapse)<{
  $nested?: boolean;
}>`
  ${ToggleHeader} {
    display: flex;
    flex-direction: column;
    position: sticky;
    overflow: hidden;
    z-index: ${({ $nested }) => ($nested ? 0 : 1)};
    padding: 8px 0;
    margin-left: -16px;
    width: calc(100% + 16px);
    padding-top: 8px;
    top: ${({ $nested }) => ($nested ? `26px` : 0)};
    background-color: ${({ theme }) => theme.backgroundColor.DEFAULT};
  }

  padding-left: ${({ $nested }) => ($nested ? "16px" : 0)};

  ${HexCollapseHeader} {
    overflow: hidden;
    width: 100%;
  }
`;

const List = styled(HexMenu)<{ $disabled: boolean }>`
  padding: 0;

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.6;
      cursor: not-allowed;
    `}
`;
