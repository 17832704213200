import {
  SortDirection as AgGridSortDirection,
  SortChangedEvent,
} from "@ag-grid-community/core";
import { OrderByDirection } from "@hex/common";
import { useCallback, useMemo } from "react";

import { HomeQueryParamsKey, useQueryParams } from "../../../route/routes.js";
import { ViewCountDuration } from "../../hex-list/ViewCountDuration.js";
import { useUpdateParams } from "../../home/project-filters/hooks/useUpdateQueryParams.js";

// useHandleSortChange types and hooks

export type SortModel<ColumnId extends string> = {
  colId: ColumnId | null;
  sort: AgGridSortDirection | undefined;
};

export type GetQueryParamsFromSortModelFunc<
  SortOrder extends string & {}, // Preserve string literal type instead of widening to `string`
  ColumnId extends string,
> = (
  params: {
    colId: ColumnId | null;
    sort: AgGridSortDirection | undefined;
  },
  duration?: ViewCountDuration,
) =>
  | {
      sort: SortOrder;
      direction: OrderByDirection;
    }
  | {
      sort: undefined;
      direction: undefined;
    };

export const useHandleSortChange = <
  Data,
  SortOrder extends string & {},
  ColumnId extends string,
>(
  getQueryParamsFromSortModel: GetQueryParamsFromSortModelFunc<
    SortOrder,
    ColumnId
  >,
  viewCountDuration?: ViewCountDuration,
  trackUpdatedSortEvent?: (params: {
    nextSortDirection: OrderByDirection;
    nextSortOrder: SortOrder;
  }) => void,
): ((sortChangedEvent: SortChangedEvent<Data>) => void) => {
  const updateParams = useUpdateParams();
  return useCallback(
    ({ columns = [], source }: SortChangedEvent<Data>) => {
      // source represents the source of the [`SortChangedEvent`](https://www.ag-grid.com/react-data-grid/grid-events/#reference-sort-sortChanged)
      // Since we only want to sync query params with the sort model if the user has manually sorted the
      // table using the AG grid column headers, we restrict this logic to the case where `source === "uiColumnSorted"`,
      // Which represents a `SortChangedEvent` that was initiated by a click on a column header
      if (columns.length > 0 && source === "uiColumnSorted") {
        // AG Grid's columns list will return all "affected" columns by the sort change as:
        // [previouslySelectedColumnToSortBy, nextSelectedColumnToSortBy] (if a column was previously sorted)
        // We can deterministically grab the most recently affect column by the last element in the list.
        const column = columns[columns.length - 1];
        if (column.isSortable()) {
          const sort = column.getSort();
          const colId = column.getColId() as ColumnId | null;

          const queryParams = getQueryParamsFromSortModel(
            {
              colId,
              sort,
            },
            viewCountDuration,
          );
          updateParams(queryParams);

          if (
            queryParams.sort != null &&
            queryParams.direction != null &&
            trackUpdatedSortEvent != null
          ) {
            trackUpdatedSortEvent({
              nextSortDirection: queryParams.direction,
              nextSortOrder: queryParams.sort,
            });
          }
        }
      }
    },
    [
      updateParams,
      getQueryParamsFromSortModel,
      trackUpdatedSortEvent,
      viewCountDuration,
    ],
  );
};

// useSelectedSortOrder type and hook

export interface SelectedSortOrderAndDirection<SortOrder> {
  sortOrder: SortOrder;
  direction: OrderByDirection;
}

/** Returns the sort order and sort direction from the page's query params */
export function useSelectedSortOrder<SortOrder>(
  defaultSelection: SelectedSortOrderAndDirection<SortOrder>,
): SelectedSortOrderAndDirection<SortOrder> {
  const queryParams = useQueryParams();

  return useMemo(() => {
    const sortParams = queryParams.get(HomeQueryParamsKey.SORT);
    if (sortParams != null) {
      const splitParams = sortParams.split(":");
      const sortOrder =
        splitParams.length > 0
          ? (splitParams[0].toUpperCase() as SortOrder)
          : defaultSelection?.sortOrder;
      const direction =
        splitParams.length > 1
          ? (splitParams[1].toUpperCase() as OrderByDirection)
          : defaultSelection?.direction;
      return {
        sortOrder,
        direction,
      };
    }
    return defaultSelection;
  }, [queryParams, defaultSelection]);
}
