import { ApolloClient, ApolloProvider } from "@apollo/client";
import { Store } from "@reduxjs/toolkit";
import { History } from "history";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { Router } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";

import {
  buildLaunchDarklyProvider,
  getLdClient,
} from "../../global-constants.js";
import { Theme } from "../../theme/common/theme";
import {
  ProjectContext,
  ProjectContextProvider,
} from "../../util/projectContext";
import {
  SessionContext,
  SessionContextProvider,
} from "../../util/sessionContext";

import { ErrorBoundary } from "./ErrorBoundary";

export type MeasurableElementCreator = ((
  setIsLoaded: () => void,
) => JSX.Element) & {
  getWidth?: (node: HTMLElement, computedWidth: number) => number | undefined;
  getHeight?: (node: HTMLElement, computedHeight: number) => number | undefined;
};

const ELEMENT_MEASURER_CONTAINER_ID = "element-measurer-container-div";

const ElementMeasurerContainerDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -100;

  opacity: 0;

  pointer-events: none;
`;

const ElementMeasurerDiv = styled.div``;

const WrapperDiv = styled.div`
  overflow: auto;
`;

export interface ElementDimensions {
  width: number;
  height: number;
}

const MINIMUM_HEIGHT = 38;
const MINIMUM_WIDTH = 56;

interface ElementMeasurerProps {
  elementCreator: MeasurableElementCreator;
  onMeasureDimensions: (dimensions: ElementDimensions) => void;
}

const ElementWrapper: React.FunctionComponent<ElementMeasurerProps> = (
  props,
) => {
  const { elementCreator, onMeasureDimensions } = props;

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement | null>();

  const measureCallback = useCallback(
    (node?: HTMLDivElement | null) => {
      if (isLoaded && node != null) {
        const { height, width } = node.getBoundingClientRect();
        const customWidth = elementCreator?.getWidth?.(node, width);
        const customHeight = elementCreator?.getHeight?.(node, height);

        onMeasureDimensions({
          // width and height from bounding client rect can be fractional, which we don't want
          // round up so we never end up with overflow
          width: Math.ceil(customWidth ?? width),
          height: Math.ceil(customHeight ?? height),
        });
      }
    },
    [isLoaded, onMeasureDimensions, elementCreator],
  );

  const setRefCallback = useCallback(
    (node: HTMLDivElement | null) => {
      ref.current = node;
      measureCallback(node);
    },
    [measureCallback],
  );

  useEffect(() => {
    if (isLoaded) {
      measureCallback(ref.current);
    }
  }, [isLoaded, measureCallback]);

  const setLoaded = useCallback(() => {
    setIsLoaded(true);
  }, []);

  const element = useMemo(
    () => elementCreator(setLoaded),
    [elementCreator, setLoaded],
  );

  return (
    <ElementMeasurerDiv>
      <WrapperDiv ref={setRefCallback}>{element}</WrapperDiv>
    </ElementMeasurerDiv>
  );
};

export const ElementMeasurer: React.FunctionComponent = () => {
  return <ElementMeasurerContainerDiv id={ELEMENT_MEASURER_CONTAINER_ID} />;
};

export const measureElement = async ({
  apolloClient,
  elementCreator,
  history,
  projectContext,
  sessionContext,
  store,
  theme,
}: {
  elementCreator: MeasurableElementCreator;
  apolloClient: ApolloClient<unknown>;
  projectContext: ProjectContext;
  history: History<unknown>;
  sessionContext: SessionContext;
  store: Store<unknown>;
  theme: Theme;
}): Promise<ElementDimensions> => {
  const ldClient = getLdClient();
  const LDProvider = ldClient
    ? await buildLaunchDarklyProvider(ldClient)
    : null;

  const child = (resolve: any, reject: any) => {
    return (
      <ReduxProvider store={store}>
        <ThemeProvider theme={theme}>
          {/* we wrap with apollo context here since
      element measurer loses react context
      and outputs may require apollo */}
          <ApolloProvider client={apolloClient}>
            <Router history={history}>
              <ProjectContextProvider value={projectContext}>
                <SessionContextProvider value={sessionContext}>
                  <ErrorBoundary onError={(err) => reject(err)}>
                    <ElementWrapper
                      key={Math.random()} // force a complete rerender every time this is called
                      elementCreator={elementCreator}
                      onMeasureDimensions={({ height, width }) => {
                        resolve({ width, height });
                      }}
                    />
                  </ErrorBoundary>
                </SessionContextProvider>
              </ProjectContextProvider>
            </Router>
          </ApolloProvider>
        </ThemeProvider>
      </ReduxProvider>
    );
  };

  return new Promise((resolve, reject) => {
    const children = child(resolve, reject);

    if (LDProvider != null) {
      ReactDOM.render(
        <LDProvider>{children}</LDProvider>,
        document.getElementById(ELEMENT_MEASURER_CONTAINER_ID),
      );
    } else {
      ReactDOM.render(
        children,
        document.getElementById(ELEMENT_MEASURER_CONTAINER_ID),
      );
    }

    setTimeout(() => reject("Element measuring timed out"), 1500);
  });
  /* eslint-enable react/jsx-no-bind */
};

export const measureElementWithDefault = async ({
  apolloClient,
  defaultDimensions,
  elementCreator,
  history,
  maxDimensions,
  projectContext,
  sessionContext,
  store,
  theme,
}: {
  elementCreator: MeasurableElementCreator;
  defaultDimensions: ElementDimensions;
  maxDimensions: ElementDimensions;
  apolloClient: ApolloClient<unknown>;
  projectContext: ProjectContext;
  history: History<unknown>;
  sessionContext: SessionContext;
  store: Store<unknown>;
  theme: Theme;
}): Promise<ElementDimensions> => {
  try {
    const { height, width } = await measureElement({
      elementCreator,
      history,
      apolloClient,
      projectContext,
      sessionContext,
      store,
      theme,
    });
    return {
      width: Math.min(Math.max(width, MINIMUM_WIDTH), maxDimensions.width),
      height: Math.min(Math.max(height, MINIMUM_HEIGHT), maxDimensions.height),
    };
  } catch (err) {
    console.error(err);
    console.warn(
      "Failed to measure element, getting default dimensions instead.",
    );
    return defaultDimensions;
  }
};
